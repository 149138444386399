import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import FancyTitle from './fancy-title';

function HeroWithText({
  title, text, image, link, pinkLink = false,
}) {
  const titleComponent = title && (
    <FancyTitle
      text={title}
      tag="h1"
      className="mb-8"
    />
  );
  return (
    <div className="grid xl:grid-cols-3 lg:grid-cols-2 items-center">
      <div className="text-left flex flex-col py-8 pt-20 lg:py-36 lg:pb-0 pr-4 lg:hidden">
        {title && (
          <div className="block lg:hidden">
            {titleComponent}
          </div>
        )}
        <GatsbyImage
          image={image}
          alt={title}
          className="w-full"
        />
      </div>
      <div className="text-left flex flex-col py-8 pt-0 lg:py-36 lg:pb-36 pr-8 lg:-order-1">
        {title && (
          <div className="hidden lg:block">
            {titleComponent}
          </div>
        )}
        {text && (
          <div>
            <p className="prose dark:text-white text-background" dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        )}
        {link && !pinkLink && (
          <Link to={link.url} className="text-latori-green font-monospace font-bold uppercase text-left text-xl pt-3">{ link.label }</Link>
        )}
        {link && pinkLink && (
          <div>
            <Link to={link.url} className="button-primary inline-block mt-4">{ link.label }</Link>
          </div>
        )}
      </div>
      <GatsbyImage
        image={image}
        alt={title}
        className="w-full xl:col-span-2 hidden lg:block"
      />
    </div>
  );
}

export default HeroWithText;
