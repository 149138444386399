import { graphql } from 'gatsby';
import * as React from 'react';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import FancyTitle from '../components/fancy-title';
// import Layout from '../components/layout'
// import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import HeroWithText from '../components/hero-with-text';
import History from '../components/history';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import GermanyMap from '../assets/germany-map.svg';

function AboutUsPage({ data }) {
  const aboutUsData = data.aboutUsYaml;
  const { items } = aboutUsData;
  library.add(faLocationDot);

  const locationsRef = React.useRef();

  React.useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    if (url && url.includes('#locations')) {
      setTimeout(() => {
        locationsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);
    }
  });

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({
          pageTitle: aboutUsData.meta_title ? aboutUsData.meta_title : aboutUsData.title,
          fullWidth: true,
        });
        return (
          <div className="px-4 lg:px-16 flex flex-col pb-[100px] lg:pb-60">
            {items && (
            <>
              <Seo
                title={aboutUsData.meta_title ? aboutUsData.meta_title : aboutUsData.title}
                description={aboutUsData.meta_description}
              />
              <HeroWithText
                image={items[0].image.childImageSharp.gatsbyImageData}
                title={items[0].title}
                text={items[0].text}
                link={items[0].link}
                pinkLink
              />
              <div className="mt-[100px] lg:mt-60">
                <FancyTitle
                  text="Unser Antrieb"
                  className="text-left"
                  tag="h2"
                />
                <p>
                  Latori entstand aus einem eigenen Projekt heraus. Unsere heutigen
                  Gründer und Geschäftsführer Jan und Natascha Laußmann planten damals,
                  einen eigenen Onlineshop zu eröffnen. Die Wahl fiel dafür auf
                  Shopify. Da das kanadische Shop-System vor 10 Jahren noch nicht ideal
                  auf den deutschen Markt vorbereitet war, mussten die beiden ihren Shopify
                  Shop selbst anpassen und durch eigene Programmierungen optimieren.
                  Das sprach sich schnell herum und die Nachfrage nach individuellen
                  Onlineshops stieg für die beiden Shopify Programmierer schnell an.
                  <br />
                  <br />
                  Was als 2-Personen-Projekt begann, ist heute eine etablierte Shopify
                  Agentur mit über 40 Shopify Experten. Unsere Mission ist jedoch
                  gleich geblieben - wir nehmen die Erstellung von Onlineshops in
                  die Hand und realisieren individuelle Wünsche. So machen wir
                  die E-Commerce-Geschäfte unserer Kunden leichter, besser und
                  zukunftsfähiger. Wir betreuen sowohl Shops für international etablierte
                  Unternehmen als auch für D2C-Marken in Deutschland, Österreich und
                  der Schweiz.
                  <br />
                  <br />
                  Transparenz und Kommunikation sind uns nicht nur unter Kollegen wichtig
                  , sondern auch in der Zusammenarbeit
                  mit unseren Kunden. Deshalb bieten wir regelmäßige Status-Calls an und
                  bleiben immer im Austausch zum
                  Projektstatus.
                  <br />
                  <br />
                  Sie sind auf der Suche nach einem Entwickler für Ihren Onlineshop?
                  Dann sind Sie bei unserem Expertenteam
                  fündig geworden. Wir freuen uns auf einen Austausch zu Ihrem Projekt.
                </p>
              </div>
              <History
                title={items[1].title}
                url={items[1].url}
                items={items[1].years}
              />
              <div className="grid sm:grid-cols-2 items-center mt-[100px] lg:mt-60 lg:mb-0">
                <div className="text-left flex flex-col" ref={locationsRef}>
                  <FancyTitle
                    text={items[2].title}
                    tag="h2"
                    className="hidden lg:block"
                  />
                  <div className="flex flex-col md:grid xl:grid-cols-2 gap-8">
                    {items[2].locations && items[2].locations.map((item) => (
                      <div key={item.title} className="flex items-start gap-2 lg:gap-4 lg:text-xl lg:leading-8 leading-8">
                        <FontAwesomeIcon icon={icon(faLocationDot)} className="mt-2" />
                        <div>
                          <div className="text-latori-green font-bold">{item.title}</div>
                          <div>{item.address}</div>
                          <div>
                            {item.zip}
                            {' '}
                            {item.city}
                          </div>
                          <div>{item.country}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-full -order-1 lg:order-2">
                  <FancyTitle
                    text={items[2].title}
                    tag="h2"
                    className="block lg:hidden"
                  />
                  <GermanyMap className="mb-8 lg:mb-0 mt-16 lg:mt-0 sm:max-h-[600px] md:max-h[800px] lg:max-h-[unset] object-contain w-full" />
                </div>
              </div>
            </>
            )}
          </div>
        );
      }}
    </LayoutConsumer>
  );
}

export default AboutUsPage;

export const aboutUsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    aboutUsYaml {
      title
      meta_title
      meta_description
      items {
        title
        link {
          label
          url
        }
        text
        image {
          childImageSharp {
            gatsbyImageData (
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        years {
          image {
            childImageSharp {
              gatsbyImageData (
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          
          text
          title
          year
        }
        locations {
          title
          address
          zip
          city
          country
        }
      }
    }
  }
`;
