import * as React from 'react';
import { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Thumbs, FreeMode } from 'swiper';
import FancyTitle from './fancy-title';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/virtual';

function History({ title, subtitle, items }) {
  const [swiper, setSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const slideTo = (index) => {
    if (!swiper || swiper.destroyed) { return; }
    swiper.slideTo(index);
    setActiveIndex(index);
  };

  const handleSlideChange = (swiperCore) => {
    const { activeIndex: _activeIndex } = swiperCore;
    setActiveIndex(_activeIndex);
    if (!thumbsSwiper || thumbsSwiper.destroyed) { return; }
    thumbsSwiper.slideTo(_activeIndex);
  };

  const activeYearClass = `
    relative
    w-fit
    font-bold
    before:content-[attr(before)]
    before:h-[4px]
    before:bg-latori-pink
    before:rounded
    before:!w-full
    before:absolute
    before:-bottom-0
    before:transition
    before:transition-width
    `;

  return (
    <>
      <div className="lg:text-center flex flex-col lg:pr-16 py-8 lg:py-0 mt-[100px] lg:mt-60">
        {title && (
          <FancyTitle
            text={title}
            subtitle={subtitle}
            marginBottom="lg:mb-10"
            textAlign="text-left"
            className="lg:w-fit lg:mx-auto"
            tag="h2"
          />
        )}
      </div>
      <Swiper
        onSwiper={setSwiper}
        modules={[Virtual, Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
        spaceBetween={0}
        slidesPerView={1}
        virtual
        onSlideChange={(swiperCore) => handleSlideChange(swiperCore)}
        autoHeight
        className="w-full cursor-grab"
      >
        {items && items.map((item, index) => (
          <SwiperSlide
            key={item.year}
            virtualIndex={index}
            className="lg:max-h-[800px]"
          >
            <div className="grid xl:grid-cols-3 lg:grid-cols-2 items-end lg:max-h-[800px]">
              {item.title && (
                <FancyTitle
                  text={item.title}
                  tag="h3"
                  marginBottom="mb-4"
                  className="lg:hidden"
                />
              )}
              <GatsbyImage
                image={getImage(item.image)}
                alt={item.title}
                className={`w-full xl:col-span-2${item.image_light ? ' hidden dark:inline-block' : ''}`}
                objectFit="contain"
              />
              {item.image_light && (
              <GatsbyImage
                image={getImage(item.image_light)}
                alt={item.title}
                className="w-full xl:col-span-2 inline-block dark:hidden"
                objectFit="contain"
              />
              )}
              <div className="text-left flex flex-col self-end lg:pl-16 py-8 lg:py-0">
                {item.title && (
                  <FancyTitle
                    text={item.title}
                    tag="h3"
                    className="lg:block hidden"
                  />
                )}
                {item.text && (
                  <div>
                    <p className="prose dark:text-white text-background" dangerouslySetInnerHTML={{ __html: item.text }} />
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        modules={[FreeMode, Thumbs]}
        onSwiper={(_swiper) => { setThumbsSwiper(_swiper); swiper?.$wrapperEl[0].classList.add('xl:justify-center'); }}
        slidesPerView={4}
        slidesPerGroup={2}
        slideToClickedSlide={false}
        className="w-full lg:mt-24 cursor-grab lg:cursor-default"
        freeMode
        breakpoints={
            {
              1023: {
                slidesPerView: items.length,
              },
            }
          }
      >
        {items && items.map((item, index) => (
          <SwiperSlide key={item.year} className="xl:flex-[0] xl:mr-8">
            {/* eslint-disable-next-line react/no-unknown-property */}
            <div before=" " className={`text-xl pb-1 before:w-0 ${activeIndex === index ? activeYearClass : ''}`}>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,
               jsx-a11y/click-events-have-key-events */}
              <span className="cursor-pointer" onClick={() => slideTo(index)}>{item.year}</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default History;
